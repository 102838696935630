<template>
  <div>
    <questionbankList
      v-if="this.$route.query.dialogStatus"
      ref="questionbankList"
    ></questionbankList>
    <questionbankList v-else ref="questionbankList" :list-status="0"></questionbankList>
  </div>
</template>

<script>
import questionbankList from '../test/components/questionbankList'
export default {
  components: {
    questionbankList,
  },
  data() {
    return {}
  },
  mounted() {
    if (this.$route.query.dialogStatus) {
      this.$refs.questionbankList.show({ dialogStatus: true })
    }
  },
  methods: {},
}
</script>

<style scoped></style>
